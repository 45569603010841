[data-amplify-authenticator] {
  --amplify-components-authenticator-router-box-shadow: 0 0 16px var(--amplify-colors-overlay-10);
  --amplify-components-authenticator-router-border-width: 0;
  --amplify-components-authenticator-form-padding: var(--amplify-space-medium) var(--amplify-space-xl) var(--amplify-space-xl);
  --amplify-components-button-primary-background-color: var(--amplify-colors-red-90);
  --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 2px var(--amplify-colors-purple-60);
  --amplify-components-tabs-item-active-border-color: var(--amplify-colors-neutral-100);
  --amplify-components-tabs-item-color: var(--amplify-colors-neutral-80);
  --amplify-components-tabs-item-active-color: var(--amplify-colors-purple-100);
  --amplify-components-button-link-color: var(--amplify-colors-purple-80);
}

#root {
  padding-top: 100px; /* Adds 100px of space above the container */
}

.App {
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.amplify-button:hover {
  background-color: #ffcccc !important; /* Hellrot */
}

main {
  font-family: 'Lato', sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  color: #e10b2f;
  font-size: 2em;
  margin-bottom: 20px;
}

.message-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  width: 100%;
}

.message-container.user {
  justify-content: flex-end;
}

.message-container.ai {
  justify-content: flex-start;
}

.user-icon, .ai-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px;
  background-size: cover;
  background-position: center center;
  flex-shrink: 0;
}

.green-icon {
  filter: invert(27%) sepia(96%) saturate(6350%) hue-rotate(119deg) brightness(95%) contrast(103%) !important;
  transform: scale(1);
  transition: transform 0.3s ease-in-out; /* Change the duration (0.3s) as needed */
}

.green-icon:hover {
  transform: scale(1.5);
}

.green-red {
  filter: invert(23%) sepia(99%) saturate(6217%) hue-rotate(356deg) brightness(101%) contrast(119%) !important;
  transform: scale(1);
  transition: transform 0.3s ease-in-out; /* Change the duration (0.3s) as needed */
}

.green-red:hover {
  transform: scale(1.5);
}

.message-box {
  max-width: 60%; /* Max width for message bubbles */
  padding: 10px;
  border-radius: 10px;
  word-wrap: break-word;
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
}

.message.user {
  align-self: flex-end;
  border-radius: 10px 10px 0 10px;
  background-color: #ffffff;
}

.message.ai {
  align-self: flex-start;
  border-radius: 10px 10px 10px 0;
  background-color: #ffffff;
}

.message.user::after {
  content: '';
  position: absolute;
  top: 0;
  right: -10px;
  width: 0;
  height: 0;
  border-top: 10px solid #fff;
  border-left: 10px solid transparent;
}

.message.ai::after {
  content: '';
  position: absolute;
  top: 0;
  left: -10px;
  width: 0;
  height: 0;
  border-top: 10px solid #ffffff;
  border-right: 10px solid transparent;
}

.feedback-container {
  display: flex;
  justify-content: space-between; /* Change from flex-end to space-between */
  align-items: center; /* Ensure all items are centered vertically */
  margin-top: 5px;
}
.feedback-button, .source-button, .copy-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  margin-left: 10px;
}

.feedback-button img, .source-button img, .copy-button img {
  width: 18px;
  height: 18px;
}

/* Add this CSS rule to give spacing around the input form */
.spaced-input-form {
  margin-top: 0.5in; /* Adds half an inch of margin above the form */
}

/* Ensure the input box and button have appropriate styling */
.input-form {
  display: flex;
  justify-content: center;
  align-items: center; /* Ensure items are centered vertically */
  margin: 0 auto;
  width: 100%;
}

.input-box {
  flex-grow: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.submit-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #e10b2f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

/* Ensure main container styles */
.main-container {
  padding-top: 0.5in; /* Adds half an inch of padding above the form */
}

button {
  margin: 20px;
  font-size: 0.8rem;
  padding: 5px 10px;
  border: none;
  border-radius: 5px; /* Corrected property name */
}

.source-container {
  flex: 1; /* Allow the source-container to take up available space */
  text-align: left; /* Ensure text is aligned to the left */
}

.thumbs-container, .copy-button-container {
  margin-left: 10px; /* Adjust as necessary */
}


.source-links a {
  font-size: small;
  text-decoration: none;
  color: #0000EE;
  margin-left: 5px;
}

.source-links a:hover {
  text-decoration: underline;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 0px;
  border-top: 1px solid #ccc;
  flex-direction: row-reverse; /* Ensure the button appears first */
}

.footer-container p {
  margin: 0;
  font-size: 0.8em; /* Changed font size */
  color: #333;
  flex-grow: 1; /* Allow the paragraph to take up available space */
  text-align: left; /* Align the text to the left */
}

.footer-container a {
  color: #e10b2f;
  text-decoration: none;
}

.footer-container a:hover {
  text-decoration: underline;
}

.footer-container button {
  font-size: 0.8rem;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  margin-top: 15; /* Remove any top margin */
  align-self: flex-start; /* Align the button to the start of the flex container */
  margin-right: 10px; /* Add some right margin to the button */
}
